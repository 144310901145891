<template>
    <!-- <b-navbar type="light" toggleable="xl" fixed="top" v-b-scrollspy:nav-scroller class="header-area" :class="{'is-sticky': scrolled}">
        <div class="container-fluid container-fluid--cp-150">
            <b-navbar-toggle target="nav_collapse"></b-navbar-toggle>
            <b-navbar-brand class="navbar-brand" to="/"><img :src='""' alt="logo"></b-navbar-brand>
            <b-btn-group class="header-config-wrapper">
                <b-btn class="header-config" @click="toggleClass('addClass', 'active')"><i class="far fa-search"></i></b-btn>
                <b-link class="ht-btn ht-btn--outline hire-btn d-none d-xl-block">Hire Us Now</b-link>
            </b-btn-group>
            <b-collapse class="default-nav justify-content-center"  is-nav id="nav_collapse">
                <b-navbar-nav class="navbar-nav main-menu">
                    <b-nav-item to="/"><span>LANDING</span></b-nav-item>
                    <b-nav-item href="#home" class="scroll"><span>HOME</span></b-nav-item>
                    <b-nav-item href="#about" class="scroll"><span>ABOUT</span></b-nav-item>
                    <b-nav-item href="#service" class="scroll"><span>SERVICE</span></b-nav-item>
                    <b-nav-item href="#pricing" class="scroll"><span>PRICING</span></b-nav-item>
                    <b-nav-item href="#faq" class="scroll"><span>FAQ</span></b-nav-item>
                    <b-nav-item href="#contact" class="scroll"><span>CONTACT</span></b-nav-item>
                </b-navbar-nav>
            </b-collapse>
        </div>
    </b-navbar> -->
 
    <!-- <header class="header-area navbar-light fixed-top" :class="{'is-sticky': isSticky}">
        <div class="container-fluid container-fluid--cp-150">
            <nav class="navbar navbar-expand-lg" id="navbar-example2">
                <router-link to="/">
                     <h4 class="text-dark">Ophelio</h4>
                </router-link>
                <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>

                <div class="header-config-wrapper btn-group">
                    <button class="header-config btn ht-btn ht-btn-default" @click="toggleClass('addClass', 'active')">
                        
                    </button>
                    <button @click="redirectToLogin"  class="ht-btn ht-btn ht-btn-default mt-1 mb-1 hire-btn d-none d-xl-block">Sign in</button>
                </div>

                <div class="collapse navbar-collapse justify-content-center" id="navbarSupportedContent">
                    <ul class="nav main-menu">
                        <li class="nav-item">
                            <a class="nav-link" href="#home" @click="menuCollapseOnLinkClick">
                                <span>HOME</span>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#about" @click="menuCollapseOnLinkClick">
                                <span>ABOUT</span>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#service" @click="menuCollapseOnLinkClick">
                                <span>SERVICE</span>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#pricing" @click="menuCollapseOnLinkClick">
                                <span>PRICING</span>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#faq" @click="menuCollapseOnLinkClick">
                                <span>FAQ</span>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#contact" @click="menuCollapseOnLinkClick">
                                <span>CONTACT</span>
                            </a>
                        </li>
                    </ul>
                </div>
            </nav>
        </div>
    </header>  -->
    <!-- <nav class="navbar navbar-expand-lg navbar-light bg-light">
  <h4 class="ml-1 text-dark" href="#">Ophelio</h4>
  <button class="navbar-toggler ml-1 mt-1" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo01" aria-controls="navbarTogglerDemo01" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse" id="navbarTogglerDemo01">
    <ul class="navbar-nav mr-auto mt-2 mt-lg-0 ml-auto">
      <li class="nav-item">
        <a class="nav-link" href="#home" @click="menuCollapseOnLinkClick">
          <span>HOME</span>
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="#about" @click="menuCollapseOnLinkClick">
          <span>ABOUT</span>
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="#service" @click="menuCollapseOnLinkClick">
          <span>SERVICE</span>
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="#pricing" @click="menuCollapseOnLinkClick">
          <span>PRICING</span>
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="#faq" @click="menuCollapseOnLinkClick">
          <span>FAQ</span>
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="#contact" @click="menuCollapseOnLinkClick">
          <span>CONTACT</span>
        </a>
      </li>
    </ul>
    <button @click="redirectToLogin" class="ht-btn ht-btn ht-btn-default mt-1 mb-1 hire-btn d-none d-xl-block">Sign in</button>
  </div>
</nav> -->
<b-navbar toggleable="lg" type="light" variant="light" class="fixed-top">
    <h4 class="ml-1 text-dark" href="#">Ophelio</h4>

    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

    <b-collapse id="nav-collapse" is-nav>
      <b-navbar-nav class="navbar-nav mr-auto mt-lg-0 ml-auto">
        <b-nav-item @click="scrollToSection('home')" >Home</b-nav-item>
        <b-nav-item @click="scrollToSection('about')">About</b-nav-item>
        <b-nav-item @click="scrollToSection('service')">Service</b-nav-item>
        <b-nav-item @click="scrollToSection('pricing')">Pricing</b-nav-item>
        <b-nav-item @click="scrollToSection('faq')">FAQ</b-nav-item>
      </b-navbar-nav>
    </b-collapse>

    <!-- Right aligned nav items -->
    <b-navbar-nav class="mr-1">
      <!-- Display the "Sign in" button for all screen sizes -->
      <button @click="redirectToLogin" class="ht-btn ht-btn-default mt-1 mb-1">Sign in</button>
    </b-navbar-nav>
  </b-navbar>
</template>

<script>
import { BNavbar, BNavbarBrand, BNavbarToggle, BNavbarNav, BNavItem, BNavForm, BFormInput, BButton, BNavItemDropdown, BDropdownItem, BCollapse } from 'bootstrap-vue';
    export default {
        components: {
            BNavbar,
            BNavbarBrand,
            BNavbarToggle,
            BNavbarNav,
            BNavItem,
            BNavForm,
            BFormInput,
            BButton,
            BNavItemDropdown,
            BDropdownItem,
            BCollapse,
        },
        data (){
            return {
                isSticky: false,
                sectionId: 'PricingSection'
            }
        },
        mounted(){
            
        },
        created() {
            // Set up a global Vue Router afterEach navigation guard
            this.$router.afterEach(() => {
            // Reload the page after the route change is complete
            window.location.reload();
            });
        },
        methods: {
            redirectToLogin() {
            // Mit $router.push die Navigation zur "/login"-Route auslösen
            this.$router.push('/login');
            
            },
            // offcanvas searchbox
            toggleClass(action, className) {
        // Method to toggle a CSS class on an element.
        if (action === 'addClass') {
            // Add a class to an element.
            // For example, if you want to add the 'active' class to an element with an ID 'myElement':
            document.getElementById('myElement').classList.add(className);
        } else if (action === 'removeClass') {
            // Remove a class from an element.
            // For example, if you want to remove the 'active' class from an element with an ID 'myElement':
            document.getElementById('myElement').classList.remove(className);
        }
        },
        
        scrollToSection(sectionId) {
          this.$emit('scroll-to-section', sectionId);
        },
        redirectToLogin() {
        // Method to handle redirection to the login page.
        // You can use Vue Router to navigate to the login page.
        this.$router.push('/login'); // Assuming you're using Vue Router
        },
        menuCollapseOnLinkClick() {
        // Method to handle collapsing the menu when a link is clicked
        // Close the menu by toggling the 'show' class
        const navbarToggle = document.querySelector('.navbar-toggler');
        const navbarCollapse = document.querySelector('.navbar-collapse');

        if (navbarToggle && navbarCollapse) {
            navbarToggle.classList.remove('collapsed');
            navbarCollapse.classList.remove('show');
        }
        },
  

        },
    }
</script>
<style lang="scss" src='@/assets/scss/styleLandingPage.scss'></style>
<style>
  .navbar-nav .nav-link {
    transition: background-color 0.1s;
  }

  .navbar-nav .nav-link:hover {
    background-color: ht-btn /* Change to the desired hover background color */
  }
</style>