<template>
    <div class="feature-icon-list">
        <div class="row">
            <div class="col-lg-6">
                <div class="feature-icon-list__media wow move-up">
                    <img src="./Assets/software-thumb-3.png" class="img-fluid" alt="">
                </div>
            </div>
            <div class="col-lg-6">
                <div class="feature-icon-list__content">
                    <div class="feature-icon-list-wrapper">
                        <div v-for="feature in features" :key="feature.id" class="single-feature-icon wow move-up">
                            <div class="single-feature-icon__media">
                                <img :src="feature.icon" class="img-fluid" alt="">
                            </div>
                            <div class="single-feature-icon__content">
                                <h6 class="title">{{ feature.title }}</h6>
                                <div class="text">{{ feature.desc }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data (){
            return {
                features: [
                    {
                        icon: require('./Assets/aeroland-branding-box-image-01-1.png'), 
                        title: 'Up-to-date data updates', 
                        desc: 'Stay on top of the market with our up-to-date stock price and market data.'
                    },
                    {
                        icon: require('./Assets/aeroland-branding-box-image-02-1.png'), 
                        title: 'Customizable watchlists', 
                        desc: 'Keep track of the stocks that matter most to you with our customizable watchlists.'
                    },
                    {
                        icon: require('./Assets/aeroland-branding-box-image-03-1.png'), 
                        title: 'Completely Mobile Ready', 
                        desc: 'Access our stock analysis web app from anywhere, at any time, with mobile compatibility.'
                    },
                ]
            }
        }
    }
</script>
<style lang="scss" src='@/assets/scss/styleLandingPage.scss'>
.move-up {
    -webkit-transform: translateY(0px);
    -ms-transform: translateY(0px);
    transform: translateY(0px);
    &.animate{
        -webkit-transform: translateY(100px);
        -ms-transform: translateY(100px);
        transform: translateY(100px);
        -webkit-animation: moveVertical .65s ease forwards;
        animation: moveVertical .65s ease forwards;
    }
}

</style>