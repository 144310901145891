<template>
    <div class="tabs-wrapper section-space--pt_120 section-space--pb_60" :class="BgGradient">
      <div class="container">
        <div class="row">
          <div class="col-lg-4">
            <div class="tab-slider-nav" id="myTab" role="tablist">
              <div
                class="tab-slider-nav--list wow move-up"
                :class="{ active: activeTab === 'home-tab' }"
                @click="activateTab('home-tab')"
                role="tab"
              >
                <div class="ht-slider-tab-icon">
                  <feather-icon icon="HomeIcon" size="35"/>
                </div>
                
                <h5>Stock Overview Page</h5>
                <p>Get a complete snapshot of the stock market with our easy-to-use Stock Overview Page.</p>
              </div>
              <div
                class="tab-slider-nav--list wow move-up"
                :class="{ active: activeTab === 'profile-tab' }"
                @click="activateTab('profile-tab')"
                role="tab"
              >
              <div class="ht-slider-tab-icon">
                  <feather-icon icon="SearchIcon" size="35"/>
                </div>
                <h5>Screening Tool</h5>
                <p>Filter stocks based on your preferred criteria and find the perfect stocks to add to your portfolio with our powerful Screening Tool.</p>
              </div>
              <div
                class="tab-slider-nav--list wow move-up"
                :class="{ active: activeTab === 'contact-tab' }"
                @click="activateTab('contact-tab')"
                role="tab"
              >
              <div class="ht-slider-tab-icon">
                  <feather-icon icon="PieChartIcon" size="35"/>
                </div>
                <h5>Test Portfolio</h5>
                <p>Try different investment strategies with our virtual portfolio feature, risk-free. Get daily feedback to improve your investment skills.</p>
              </div>
            </div>
          </div>
          <div class="col-lg-8">
            <div class="tab-content" id="myTabContent">
              <div id="home-tab" class="tab-pane fade show" :class="{ active: activeTab === 'home-tab' }" role="tabpanel">
                <div class="pro-large-img img-zoom wow move-up">
                  <img class="img-fluid" src="./Assets/Overview.png" alt="product-details" />
                </div>
              </div>
              <div id="profile-tab" class="tab-pane fade show" :class="{ active: activeTab === 'profile-tab' }" role="tabpanel">
                <div class="pro-large-img img-zoom wow move-up">
                  <img class="img-fluid" src="./Assets/Screener.png" alt="product-details" />
                </div>
              </div>
              <div id="contact-tab" class="tab-pane fade show" :class="{ active: activeTab === 'contact-tab' }" role="tabpanel">
                <div class="pro-large-img img-zoom wow move-up">
                  <img class="img-fluid" src="./Assets/Portfolio.png" alt="product-details" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        activeTab: 'home-tab'
      };
    },
    methods: {
      activateTab(tabId) {
        this.activeTab = tabId;
      }
    },
    props: {
      BgGradient: {
        type: String,
        default: ''
      }
    }
  };
  </script>
  
  <style scoped>
  .tab-slider-nav--list {
    /* Add your custom styles here */
  }
  
  .tab-pane.fade {
    /* Add your custom styles here */
  }

  .icon-smaller {
  font-size: 20px; /* Adjust the size as needed */
}
  </style>
  <style lang="scss" src='@/assets/scss/styleLandingPage.scss'></style>