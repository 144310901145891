<template>
    <div class="accordion-faq wow move-up">
      <div class="accordion" :id="accordionId">
        <div class="accordion-item" v-for="(item, index) in items" :key="index">
          <div class="accordion-header" :id="'heading' + index">
            <button
              class="accordion-button"
              type="button"
              :class="{ 'collapsed': activeIndex !== index }"
              :data-bs-toggle="activeIndex === index ? 'collapse' : null"
              :data-bs-target="'#collapse' + index"
              :aria-expanded="activeIndex === index ? 'true' : 'false'"
              :aria-controls="'collapse' + index"
              @click="toggleAccordion(index)"
            >
              {{ item.question }}
            </button>
          </div>
          <transition name="accordion">
            <div
              :id="'collapse' + index"
              class="accordion-collapse collapse"
              :class="{ 'show': activeIndex === index }"
              :aria-labelledby="'heading' + index"
              :data-bs-parent="accordionId"
            >
              <div class="accordion-body">
                <div class="accordion-content">
                  {{ item.answer }}
                </div>
              </div>
            </div>
          </transition>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        activeIndex: null,
        items: [
          {
            question: "What payment options are available for Ophelio?",
            answer:
              "We offer a range of payment options for Ophelio, including credit card and PayPal. You can choose the option that works best for you."
          },
          {
            question: "How do I cancel my subscription to Ophelio?",
            answer:
              "To cancel your subscription to Ophelio, simply log in to your account and follow the cancellation process. If you have any issues or concerns, our customer support team is available to assist you."
          },
          {
            question: "Is there a minimum subscription period for Ophelio?",
            answer:
              "Yes, we require a minimum subscription period of one month for Ophelio. This ensures that users have enough time to fully experience and benefit from our app's features."
          },
          {
            question: "What customer support options are available for Ophelio?",
            answer:
              "We offer a range of customer support options for Ophelio, including email support, phone support, and a knowledge base with frequently asked questions. Our team is dedicated to providing the highest level of support to ensure that you have a great experience using our app."
          },
          // Add more items as needed
        ],
        accordionId: 'accordionExample' // Provide a unique ID for the accordion
      };
    },
    mounted() {
      const storedIndex = localStorage.getItem("activeIndex");
      if (storedIndex !== null) {
        this.activeIndex = parseInt(storedIndex);
      }
    },
    methods: {
      toggleAccordion(index) {
        this.activeIndex = this.activeIndex === index ? null : index;
        localStorage.setItem("activeIndex", this.activeIndex);
      }
    }
  };
  </script>

<style lang="scss" src='@/assets/scss/styleLandingPage.scss'>
.move-up {
    -webkit-transform: translateY(0px);
    -ms-transform: translateY(0px);
    transform: translateY(0px);
    &.animate{
        -webkit-transform: translateY(100px);
        -ms-transform: translateY(100px);
        transform: translateY(100px);
        -webkit-animation: moveVertical .65s ease forwards;
        animation: moveVertical .65s ease forwards;
    }
}
</style>