<template>
    <div class="icon-process-wrapper">
        <div class="icon-process-single wow move-up">
            <div class="icon-process-single__line">
                <div class="dot dot-1"></div>
                <div class="dot dot-2"></div>
                <div class="dot dot-3"></div>
                <div class="dot dot-4"></div>
                <div class="dot dot-5"></div>
                <div class="dot dot-4"></div>
                <div class="dot dot-3"></div>
                <div class="dot dot-2"></div>
                <div class="dot dot-1"></div>
            </div>
            <div class="icon-process-single__content-wrap">
                <div class="icon-process-single__media">
                    <i class="fa fa-fire"></i>
                    <feather-icon icon="SearchIcon"  size="35"/>
                </div>
                <h5 class="icon-process-single__title">Research and Analysis</h5>
                <div class="icon-process-single__text">
                    Discover Your Next Big Investment - Conduct In-Depth Research and Analysis with Ophelio
                </div>
            </div>
        </div>
        <div class="icon-process-single wow move-up">
            <div class="icon-process-single__line">
                <div class="dot dot-1"></div>
                <div class="dot dot-2"></div>
                <div class="dot dot-3"></div>
                <div class="dot dot-4"></div>
                <div class="dot dot-5"></div>
                <div class="dot dot-4"></div>
                <div class="dot dot-3"></div>
                <div class="dot dot-2"></div>
                <div class="dot dot-1"></div>
            </div>
            <div class="icon-process-single__content-wrap">
                <div class="icon-process-single__media">
                   
                    <feather-icon icon="PieChartIcon"  size="35"/>
                </div>
                <h5 class="icon-process-single__title">Portfolio Diversification</h5>
                <div class="icon-process-single__text">
                    Diversify Your Portfolio and Minimize Risk To Your Preferences
                </div>
            </div>
        </div>
        <div class="icon-process-single wow move-up">
            <div class="icon-process-single__line">
                <div class="dot dot-1"></div>
                <div class="dot dot-2"></div>
                <div class="dot dot-3"></div>
                <div class="dot dot-4"></div>
                <div class="dot dot-5"></div>
                <div class="dot dot-4"></div>
                <div class="dot dot-3"></div>
                <div class="dot dot-2"></div>
                <div class="dot dot-1"></div>
            </div>
            <div class="icon-process-single__content-wrap">
                <div class="icon-process-single__media">
                    <i class="fa fa-chart-line"></i>
                    <feather-icon icon="UsersIcon"  size="35"/>
                </div>
                <h5 class="icon-process-single__title">Adjustment Management</h5>
                <div class="icon-process-single__text">
                    Stay Ahead of the Market with Our Ongoing Portfolio Monitoring and Adjustments.
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default{
        name: 'Gradation',
    };
</script>
<style lang="scss" src='@/assets/scss/styleLandingPage.scss'></style>