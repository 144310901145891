<template>
    <div class="accordion-faq wow move-up">
      <div class="accordion" :id="accordionId">
        <div class="accordion-item" v-for="(item, index) in items" :key="index">
          <div class="accordion-header" :id="'heading' + index">
            <button
              class="accordion-button"
              type="button"
              :class="{ 'collapsed': activeIndex !== index }"
              :data-bs-toggle="activeIndex === index ? 'collapse' : null"
              :data-bs-target="'#collapse' + index"
              :aria-expanded="activeIndex === index ? 'true' : 'false'"
              :aria-controls="'collapse' + index"
              @click="toggleAccordion(index)"
            >
              {{ item.question }}
            </button>
          </div>
          <transition name="accordion">
            <div
              :id="'collapse' + index"
              class="accordion-collapse collapse"
              :class="{ 'show': activeIndex === index }"
              :aria-labelledby="'heading' + index"
              :data-bs-parent="accordionId"
            >
              <div class="accordion-body">
                <div class="accordion-content">
                  {{ item.answer }}
                </div>
              </div>
            </div>
          </transition>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        activeIndex: null,
        items: [
          {
            question: "What is Ophelio?",
            answer:
              " Ophelio is a subscription-based web app that provides users with an easy-to-use platform for managing their investments. Our app is designed to help users make informed investment decisions and maximize their potential returns."
          },
          {
            question: "How does Ophelio work?",
            answer:
              " Ophelio works by analyzing investment data from various sources and providing users with personalized investment recommendations based on their unique financial goals and risk tolerance. Our app's user-friendly interface makes it easy to track and manage your investments."
          },
          {
            question: "What features are included in Ophelio?",
            answer:
              " Ophelio includes a range of features designed to help users manage their investments, including personalized investment recommendations, market updates, screening tools, portfolio tracking, and more."
          },
          {
            question: " How do I sign up for a subscription to Ophelio?",
            answer:
              " To sign up for a subscription to Ophelio, simply visit our website and follow the sign-up process. Once you have signed up, you will have access to all of our app's features."
          },
          // Add more items as needed
        ],
        accordionId: 'accordionExample' // Provide a unique ID for the accordion
      };
    },
    mounted() {
      const storedIndex = localStorage.getItem("activeIndex");
      if (storedIndex !== null) {
        this.activeIndex = parseInt(storedIndex);
      }
    },
    methods: {
      toggleAccordion(index) {
        this.activeIndex = this.activeIndex === index ? null : index;
        localStorage.setItem("activeIndex", this.activeIndex);
      }
    }
  };
  </script>

<style lang="scss" src='@/assets/scss/styleLandingPage.scss'>
.move-up {
    -webkit-transform: translateY(0px);
    -ms-transform: translateY(0px);
    transform: translateY(0px);
    &.animate{
        -webkit-transform: translateY(100px);
        -ms-transform: translateY(100px);
        transform: translateY(100px);
        -webkit-animation: moveVertical .65s ease forwards;
        animation: moveVertical .65s ease forwards;
    }
}

</style>