<template>
    <div class="hero-software hero-swiper-btn" id="home">
        <div class="container_f1">
            <div class="position-relative">
                <div class="hero-item">
                            <div class="hero-content">
                                <h2 class="h1 hero-content-title">Your portfolio deserves better software</h2>
                                <h6 class="hero-content-subtitle">little to no investing skills required.</h6>
                                <button @click="scrollToSection" class="btn btn--green">
                                    <span class="btn-icon  ml-0 mr-2"><i class="fa fa-arrow-alt-to-bottom"></i></span> Get Ophelio
                                </button>
                            </div>
                            <div class="hero-thumb pr-100">
                                <img src="./Assets/software-thumb-3.png" class="img-fluid" alt="hero thumb">
                            </div>
                        </div>
            </div>
        </div>
    </div>
</template>

<script>
    
    
    export default {
        components: {
           
        },
        data() {
            
        },
        methods: {
            scrollToSection() {
                const sectionElement = document.getElementById('pricingSection');
                sectionElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
        }
    };
</script>

<style lang="scss"> 
    @import 'src/assets/scss/_variabls.scss';
    @import 'src/assets/scss/_hero-software.scss';
</style>
