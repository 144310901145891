<template>
	<div class="main-container">
		<HeaderSoftware  @scroll-to-section="scrollToSection"/>
		<HeroSoftware  id="home"/>


        <div class="feature-icon-list-area section-space--pb_120 section-space--mt_40" id="about">
            <div class="container">
                <div class="section-title-wrapper text-center section-space--mb_100">
                    <h2 class="section-title font-weight--light mb-30 wow move-up">In-depth Stock-Analysis Available at <br> Your Hand</h2>
                    <div class="video-button-container video-popup">
                        
                    </div>
                </div>
                <FeatureList v-scrollanimation  />
            </div>
        </div>

        <!-- gradation component start -->
        <div class="icon-process-area section-space--ptb_120">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <Gradation v-scrollanimation/>
                    </div>
                </div>
            </div>
        </div>
        <!-- gradation component end -->


        <TabTwo BgGradient="bg-gradient" id="service" />

        
		<div class="fun-fact-wrapper section-space--pt_60 section-space--pb_60">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="section-title text-center section-space--mb_60">
                            <h2 class="font-weight--light mb-15 wow move-up">No stories, facts only</h2>
                            <span class="section-text wow move-up">Better data promotes overall portfolio return</span>
                        </div>
                    </div>
                </div>
				<FunFactOne />
            </div>
		</div>

        <CurveShape>
            <template v-slot:bgcolor>
                <path style="fill: #f6f5f9;
                    stroke-miterlimit:10;" 
                    d="M3360.5,97.739c-242,0-480-48.375-480-48.375
                        S2647.5,0.5,2400.5,0.5s-480,48.375-480,48.375s-238,48.864-480,48.864s-480-48.375-480-48.375S727.5,0.5,480.5,0.5
                        S0.5,48.875,0.5,48.875V108h1920h1920V48.875C3840.5,48.875,3602.5,97.739,3360.5,97.739z"/>
            </template>
        </CurveShape>
        <div class="pricing-table-area bg-gray-2 section-space--pb_90" id="pricing">
            <div class="pricing-table-title-area position-relative ">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12  wow move-up">
                            <div class="section-title-wrapper text-center section-space--mb_60">
                                <h2 class="section-title font-weight--light mb-15 wow move-up">Affordable for everyone!</h2>
                                <span class="section-text wow move-up">If you are planning be your own Investment-Adviser sign-up and start now!</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="pricing-table-content-area">
                <div class="container">
                    <pricingTwo />
                </div>
            </div>
        </div>

        <CtaThree />

        <div class="software-accordion-wrapper section-space--pt_120 section-space--pb_60 gray-gradient" id="faq">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="section-title-wrapper text-center section-space--mb_60">
                            <h2 class="section-title font-weight--light mb-10 wow move-up">Frequently Asked Questions</h2>
                            <span class="section-text wow move-up">It’s our pleasure to answer any questions you have</span>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-6">
                        <div class="faq-wrapper">
                            <Accordion />
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="faq-wrapper soft-accordion-two">
                            <Accordion2 />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        


        <Newsletter /> 
        <FooterMain />
        
	</div>
</template>

<script>
	import HeaderSoftware from "./HeaderSoftware";
	import HeroSoftware from "./HeroSoftware";
	import FeatureList from "./FeatureList";
	import TabTwo from "./TabTwo";
	import pricingTwo from "./pricingTwo";
	import CurveShape from "./CurveShape";
	import CtaThree from "./CtaThree";
	import FunFactOne from "./FunFactOne";
	import Accordion from "./Accordion";
	import Newsletter from "./Newsletter";
	import FooterMain from "./FooterMain";
    import Gradation from './Gradation'
import Accordion2 from "./Accordion2.vue";


	export default {
		name: "HomeSoftware",
		components: {
    HeaderSoftware,
    HeroSoftware,
    
    FeatureList,
    TabTwo,
    pricingTwo,
    CurveShape,
    CtaThree,
    FunFactOne,
    Accordion,
    Newsletter,
    FooterMain,
    Gradation,
    Accordion2
},
methods: {
    scrollToSection(sectionId) {
      const sectionElement = document.getElementById(sectionId);
      if (sectionElement) {
        sectionElement.scrollIntoView({ behavior: 'smooth' });
      }
    }
}
}
</script>





<style lang="scss" src='@/assets/scss/styleLandingPage.scss'>

.before-enter {
    opacity: 0;
    transform: translateY(100px);
    transition: all 2s;
}

.enter {
    opacity: 1;
    transform: translateY(0px);
}

</style>