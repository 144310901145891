<template>
    <div class="row pricing-table-two"  id="pricingSection" ref="pricingSection">
        <div class="col-md-6 col-lg-4 pricing-table pricing-table--popular wow move-up" v-for="(pricing, index) in pricingTable" :key="index">
            <div class="pricing-table__inner">
                <div class="pricing-table__feature-mark" v-if="pricing.isPopular">
                    <span>Popular Choice</span>
                </div>
                <div class="pricing-table__header">
                    <div class="pricing-table__image">
                        <img :src="pricing.thumb" class="img-fluid" :alt="pricing.alt">
                    </div>
                    <h5 class="pricing-table__title">{{ pricing.title }}</h5>
                    <div class="pricing-table__price-wrap">
                        <h6 class="price">${{ pricing.value }}</h6>
                    </div>
                </div>
                <div class="pricing-table__body">
                    <div class="pricing-table__footer">
                        <button class="ht-btn ht-btn-default" :class="{'btn--green': pricing.active}" @click="ChangePurchaseOption(pricing.buyLink)">Get started</button>
                    </div>
                    <ul class="pricing-table__list text-left">
                        <li v-for="(listitem, listindex) in pricing.price" :key="listindex">{{ listitem }}</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import index from 'vue-prism-component'
    export default {
        name: 'pricingTwo',
        data(){
            return {
                PurchaseOption: 0,
                pricingTable: [
                    {
                        thumb: require('./Assets/aeroland-pricing-image-06.png'),
                        title: 'Beginner',
                        value: '8.99',
                        alt: 'Pricing Thumb',
                        buyLink: 'https://buy.stripe.com/dR6bK66Xe4Q3co0eV2',
                        isPopular: false,
                        active: false,
                        price: ['15 Years Historical Data', 'Watchlist', 'Financial Ratios']
                    },
                    {
                        thumb: require('./Assets/aeroland-pricing-image-05.png'),
                        title: 'Analyst',
                        value: '14.99',
                        alt: 'Pricing Thumb',
                        buyLink: 'https://buy.stripe.com/4gw01o2GY0zNfAc14a',
                        isPopular: true,
                        active: true,
                        price: ['15 Years Historical Data', 'Watchlist','Financial Ratios', 'Institutional Holders', 'Insider Activity']
                    },
                    {
                        thumb: require('./Assets/aeroland-pricing-image-07.png'),
                        title: 'Professional',
                        value: '19.99',
                        alt: 'Pricing Thumb',
                        buyLink: 'https://buy.stripe.com/aEU7tQ95m2HV2Nq004',
                        isPopular: false,
                        active: false,
                        price: ['15 Years Historical Data','Screeners', 'Your Own Test Portfolio', 'Watchlist','Financial Ratios', 'Institutional Holders', 'Insider Activity']
                    }
                ]
            }
        },
        methods : {
            ChangePurchaseOption: function(index) {
      
                // this.$store.dispatch('setPurchaseOption', index)
                console.log(index)
                window.location.href = index
            }
         },
    }
</script>
<style lang="scss" src='@/assets/scss/styleLandingPage.scss'></style>