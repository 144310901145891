import { render, staticRenderFns } from "./CurveShape.vue?vue&type=template&id=43c06cc6&scoped=true&"
var script = {}
import style0 from "./CurveShape.vue?vue&type=style&index=0&id=43c06cc6&lang=scss&scoped=true&"
import style1 from "@/assets/scss/styleLandingPage.scss?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "43c06cc6",
  null
  
)

export default component.exports