<template>
    <div class="cta-image-area cta-image-bg_02">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="cta-image">
                        <div class="row align-items-center">
                            <div class="col-lg-6 order-2 order-lg-1">
                                <div class="cta-image__content">
                                    <h4 class="cta-image__title text-white mb-20 wow move-up">Sign up for our app now</h4>
                                    <div class="cta-image__text text-white mb-20 wow move-up">Offer your portfolio the best assistance for growth.</div>
                                    <button @click="scrollToSection" class="btn btn--green wow move-up">
                                        <span class="btn-icon  ml-0 mr-2"></span> Get Ophelio
                                    </button>
                                </div>
                            </div>
                            <div class="col-lg-6 order-1 order-lg-2">
                                <div class="cta-image_two section-space--mt_60 wow move-up">
                                    <img src="./Assets/aeroland-macbook-01.png" class="img-fluid" alt="aeroland-macbook-01">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'CtaThree',
        methods:{
            scrollToSection() {
                const sectionElement = document.getElementById('pricingSection');
                sectionElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
        }
    }
</script>
<style lang="scss" src='@/assets/scss/styleLandingPage.scss'>
.move-up {
    -webkit-transform: translateY(0px);
    -ms-transform: translateY(0px);
    transform: translateY(0px);
    &.animate{
        -webkit-transform: translateY(100px);
        -ms-transform: translateY(100px);
        transform: translateY(100px);
        -webkit-animation: moveVertical .65s ease forwards;
        animation: moveVertical .65s ease forwards;
    }
}

</style>